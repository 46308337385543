import React from "react"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { gql, useQuery } from "@apollo/client"
import LoadingScreen from "./LoadingScreen"
import ErrorScreen from "./ErrorScreen"
import { Button, Empty, Row, Space, Table } from "antd"
import AtsCarousel from "./AtsCarousel"
import { devLog, formatDate, formatPrice, renderCarouselItem } from "../utils"
import { useTranslation } from "react-i18next"

const GET_ITEMS_QUERY = gql`
  query getItems($ids: [uuid!]) {
    user_purchases(
      where: { state: { _eq: COMPLETED } }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      price
      final_price
      currency
      item_id
      item_type
      description
      video {
        __typename
        price_policy
        id
        state
        title
        subtitle
        level
        landscape {
          s3_key
        }
        seo {
          slug
        }
      }
      course {
        __typename
        price_policy
        id
        title
        subtitle
        level
        landscape {
          s3_key
        }
        portrait {
          s3_key
        }
        seo {
          slug
        }
        videos {
          video {
            state
            duration
          }
        }
      }
    }
    courses(where: { id: { _in: $ids } }) {
      __typename
      price_policy
      id
      title
      subtitle
      level
      short_description
      videos {
        video {
          state
          duration
        }
      }
      landscape {
        s3_key
      }
      portrait {
        s3_key
      }
      seo {
        slug
      }
    }
  }
`

const UserPurchasedItems = ({ showPaymentHistory = false }) => {
  const [{ userInventory }] = useGlobalValue()
  const ids = (userInventory || []).map(item => item.item_id)
  const { loading, error, data } = useQuery(GET_ITEMS_QUERY, {
    variables: { ids },
  })

  const { t } = useTranslation()

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { courses, user_purchases } = data

  devLog({ courses })

  const columns = [
    {
      title: t("label:date"),
      render: record => {
        return formatDate(record.created_at, { format: "LLL" })
      },
    },
    {
      title: t("label:type"),
      render: record => {
        return record.item_type
      },
    },
    {
      title: t("label:description"),
      render: record => {
        return record.description
      },
    },
    {
      title: t("label:price"),
      render: record => {
        return formatPrice(record.final_price ?? record.price)
      },
    },
    {
      title: "",
      render: record => {
        return (
          <Space>
            <Button type="primary" size="large" className="green">
              <a href={`/invoice?purchaseId=${record.id}`} target="_blank">
                {t("button:downloadInvoice")}
              </a>
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <div>
      <Row>
        <AtsCarousel
          isVertical={true}
          title={t("label:purchasedCourses")}
          dataSource={courses}
          renderItem={renderCarouselItem}
          emptyItem={<Empty description={t("message:noCoursePurchased")} />}
        />
      </Row>
      {showPaymentHistory && (
        <div className="storico-transazioni">
          <br />
          <br />
          <h1>{t("label:history")}</h1>
          <hr />
          <Table rowKey={"id"} columns={columns} dataSource={user_purchases} />
        </div>
      )}
    </div>
  )
}

export default UserPurchasedItems
