import React from "react"
import PageRoot from "../../../components/PageRoot"
import UserPurchasedItems from "../../../components/UserPurchasedItems"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"

const UserPurchaseListPage = () => {
  const { t } = useTranslation()
  return (
    <PageRoot title={t("label:myPurchases")} showTitle>
      <ContainerWithPadding size={"default"}>
        <UserPurchasedItems showPaymentHistory={true} />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default UserPurchaseListPage
